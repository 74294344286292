import React, { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import PropTypes from "prop-types";

import Layout from "../components/layout";
import SEO from "../components/seo";

import {
  AlertModal,
  Button,
  Hero,
  Input,
  PasswordValidation,
} from "../components/common";

import { useAuth } from "../hooks";
import { ALERT_INITIAL_STATE, apiURL } from "../helpers";
import axios from "axios";

import "./account.css";

function AccountPage({ location }) {
  const [state, setState] = useState({
    firstName: "",
    email: "",
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    showResetPassword: false,
    alert: ALERT_INITIAL_STATE,
    validation: {
      name: true,
      email: true,
      password: true,
      "new-password": true,
      "confirm-password": true,
    },
  });
  const { state: authState, isAuthenticated } = useAuth();
  const { user = null } = authState;

  useEffect(() => {
    setState({ ...state, firstName: user?.firstName, email: user?.email });
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  const {
    firstName,
    email,
    showResetPassword,
    currentPassword,
    newPassword,
    confirmNewPassword,
  } = state;

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }

    let editedObj = {
      firstName,
      email,
    };
    if (
      showResetPassword &&
      currentPassword &&
      newPassword &&
      newPassword === confirmNewPassword
    ) {
      editedObj = { ...editedObj, currentPassword, newPassword };
    }
    const { data } = await axios.put(
      apiURL + `/profiles/${user.id}`,
      editedObj,
      {
        headers: {
          Authorization: `Bearer ${authState.jwt}`,
        },
      }
    );
    setState({
      ...state,
      alert: {
        success: data.succes,
        error: !data.succes,
        title: data.data.title,
        content: data.data.content,
      },
    });
  };

  const onValidate = (name, status) => {
    setState({ ...state, validation: { ...state.validation, [name]: status } });
  };

  return (
    <Layout>
      <AlertModal
        show={state.alert.error || state.alert.succes}
        type={state.alert.error ? "error" : "success"}
        title={state.alert.title}
        content={state.alert.content}
        onClose={() => setState({ ...state, alert: ALERT_INITIAL_STATE })}
      />
      <SEO
        keywords={[`vackground`, `pattern`, `background`, `design`]}
        title="Account"
        path={location?.pathname}
      />
      <section className="mt-20 mb-16">
        <Hero
          title="Account Settings"
          description="Here you can change your name, email address, passwords and other stuffs for your security purpose"
        />
      </section>
      {user && (
        <div className="dev-vg-account-settings h-full flex flex-col mb-24">
          <div className="flex-1 flex justify-center items-center">
            <div className="dev-vg-account-settings-content">
              <form onSubmit={handleSubmit} className="flex flex-col gap-y-8">
                <Input
                  name="name"
                  label="First name"
                  placeholder="John Doe"
                  value={state?.firstName}
                  onChange={(firstName) => setState({ ...state, firstName })}
                  minLength={3}
                  maxLength={32}
                  onValidate={onValidate}
                />
                <Input
                  pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                  name="email"
                  label="Email address"
                  placeholder="Email address"
                  value={state?.email}
                  onChange={(email) => setState({ ...state, email })}
                  onValidate={onValidate}
                />
                <button
                  className="underline text-left font-bold text-lg leading-7 tracking-tightsy"
                  onClick={(e) => {
                    e.preventDefault();

                    setState({
                      ...state,
                      showResetPassword: !state.showResetPassword,
                    });
                  }}
                >
                  Change Password?
                </button>
                {state.showResetPassword && (
                  <>
                    <Input
                      type="password"
                      name="current-password"
                      label="Current password"
                      placeholder="Enter current password"
                      value={state?.currentPassword}
                      onChange={(currentPassword) =>
                        setState({ ...state, currentPassword })
                      }
                      pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                    />
                    <Input
                      type="password"
                      name="new-password"
                      label="New password"
                      placeholder="Enter new password"
                      value={state?.newPassword}
                      onChange={(newPassword) =>
                        setState({ ...state, newPassword })
                      }
                      pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                      tooltip={
                        <PasswordValidation
                          name={state.firstName}
                          email={state.email}
                          password={state.newPassword}
                        />
                      }
                    />
                    <Input
                      type="password"
                      name="confirm-password"
                      label="Confirm new password"
                      placeholder="Re-type new password"
                      value={state?.confirmNewPassword}
                      onChange={(confirmNewPassword) =>
                        setState({ ...state, confirmNewPassword })
                      }
                      pattern="(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$"
                    />
                  </>
                )}
                <Button
                  hasMinWidth={false}
                  disabled={
                    showResetPassword
                      ? !(
                          state.validation.name &&
                          state.validation.email &&
                          state.validation["password"] &&
                          state.validation["new-password"] &&
                          state.validation["confirm-password"] &&
                          firstName &&
                          email &&
                          currentPassword &&
                          newPassword &&
                          newPassword === confirmNewPassword
                        )
                      : !(
                          state.validation.name &&
                          state.validation.email &&
                          firstName &&
                          email
                        )
                  }
                  text={
                    state.showResetPassword
                      ? "Save Changes"
                      : "Save Information"
                  }
                />
              </form>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

AccountPage.propTypes = {
  location: PropTypes.object,
};

export default AccountPage;
